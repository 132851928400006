.experience__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 0.7rem 0.7rem;
  text-align: center;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  font-size: 1.2rem;
  margin-top: 6px;
  color: var(--color-primary);
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ========== */
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* ========== MEDIA QUERIES (SMALL DEVICES) ========== */
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem;
  }
}

.experience__container1 {
  display: grid;
  gap: 3rem;
}

.experience__container1 > div {
  background: var(--color-bg-variant);
  padding: 1.2rem 1.2rem;
  /* text-align: center; */
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container1 > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}
.experience__container1 > div h3 {
  /* text-align: center; */
  margin-bottom: 2rem;
  color: var(--color-primary);
}
